import * as styles from './index.module.scss'

import { graphql } from 'gatsby'
import React from "react"
import Container from '../../components/container'
import FormatDate from '../../components/date'
import Divider from '../../components/divider'
import DownloadsListing from '../../components/downloads/listing'
import Gallery from '../../components/gallery'
import Layout from '../../components/layout/index'
import SectionsRenderer from '../../components/sections/renderer'
import Seo from '../../components/seo'
import TextRenderer from '../../components/text/renderer'
import { pathTo } from '../../routes'

const Post = ({ data: { post } }) => {
  const { title, publishDate, body, metaTitle, metaDescription, metaKeywords, sharingTitle, sharingDescription, sharingImage, downloads, photos, sections, gallery } = post

  return (
    <>
      <Seo
        title={title}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        sharingTitle={sharingTitle}
        sharingDescription={sharingDescription}
        sharingImage={sharingImage?.file?.url}
        currentUrl={pathTo(post)}
        keywords={metaKeywords}
      />
      <Layout>
        <Container>
          <div className={styles.header}>
            <h1 className={styles.title}>{title}</h1>
            <div className={styles.publishDate}>
              <FormatDate date={publishDate} />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.body}>
              {(body?.body &&
                <TextRenderer text={body?.body} />
              )}
              {(sections && sections.length > 0 &&
                <div className={styles.sections}>
                  <SectionsRenderer sections={sections} />
                </div>
              )}
            </div>
            <div className={styles.divider}>
              <Divider />
            </div>
            <div className={styles.sidebar}>
              <div className={styles.contact}>
                <div>Pressekontakt</div>
                <div>Nadine Remus</div>
                <div>
                  <a href='tel:+498948003583'>+49 89 48003-583</a>
                </div>
                <div>
                  <a href='mailto:presse@musikautorinnenpreis.de'>presse@musikautorinnenpreis.de</a>
                </div>
              </div>
              {(downloads && downloads.length > 0 &&
                <>
                  <div className={styles.dividerDownloads}>
                    <Divider size='small' />
                  </div>
                  <DownloadsListing downloads={downloads} />
                </>
              )}
            </div>
          </div>
          {(gallery && gallery.length > 0 &&
            <div className={styles.gallery}>
              {gallery.map((g, index) => (
                <Gallery key={index} title={getValue(g.title)} teaser={getValue(g.body && g.body.body)} preview={getValue(g.cover)} download={(g.download && g.download.file && g.download.file.url) || undefined} photos={getValue(g.photos)} />
              ))}
            </div>
          )}
        </Container>
      </Layout>
    </>
  )
}

function getValue(value) {
  return value !== null ? value : undefined
}

export default Post

export const query = graphql`
  query PostQuery($id: String!) {
    post: contentfulPost(id: { eq: $id }) {
      __typename
      body {
        body
      }
      category
      publishDate
      slug
      title
      sharingImage {
        file {
          url
        }
      }
      metaKeywords
      sharingDescription {
        sharingDescription
      }
      sharingTitle
      metaTitle
      metaDescription {
        metaDescription
      }
      gallery {
        ...Gallery
      }
      downloads {
        title
        url
        download {
          file {
            url
          }
        }
      }
      photos {
        id
        description
        file {
          url
        }
      }
      sections {
        __typename
        ... on ContentfulYouTubePlayer {
          ...YouTubePlayer
        }
      }
    }
  }
`

// TODO: image, photos, cover, photos, sharing image
